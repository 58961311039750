/*  Add Staff Modal Css Start */

.modal-staff {
  border-radius: 10px;
}

.head {
  padding: 20px 16px;
  height: 60px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0 0;
}

.head p {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 13px 10px;
  font-weight: 410;
}

.head p h4 {
  margin-top: -1px;
}

.po_modal {
  border-radius: 3px;
  position: relative;
  top: 320px;
}

.po_modall {
  border-radius: 3px;
  position: relative;
  top: 360px;
}

.head div {
  display: flex;
  gap: 5px;
  align-items: center;
}

.head div i {
  color: black;
  font-size: 16px;
}

.head button {
  border: none;
  /* background-color: transparent; */
}

.head h4 {
  font-size: 16px;
  font-weight: 700;
  margin: 0px 0 0 5px;
  letter-spacing: 0.4px;
}

.modal-staff-body {
  margin: 20px 0 25px 40px;
}

.modal-staff-body h4 {
  margin: 0px 0px 0px -15px;
  font-size: 15px;
  font-weight: 450;
  letter-spacing: 0.4px;
}

.modal-form {
  margin: 20px 0 0px;
  padding-bottom: 20px;
}

.modal-form .form-control {
  margin-bottom: 25px;
}

.modal-form .form-control {
  color: black;
  /* font-size: 12px; */
}

.modal-form-row h4 {
  margin: 10px 0 0 20px;
}

.modal-form .form-control:focus {
  border-color: #ff7f03 !important;
  background-color: #ffffff;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

::placeholder {
  color: #aaaaaa;
  font-size: 16px;
}

.form-div {
  margin: 0px 0px 20px;
}

.modal-form .form-label {
  position: relative;
  font-size: 14px;
  font-weight: 450;
  left: -12px;
}

.form-radio {
  display: flex;
  flex-wrap: nowrap;
  flex-flow: row;
  margin: 15px 0 10px;
  padding: 0px 10px 0 0;
}

.form-radio div {
  margin: 0 5px;
  padding: 0px 5px 0 0;
}

.form-radio div label {
  font-size: 14px;
  font-weight: 450;
  margin-left: 2px;
}

.modal-password {
  background-color: #1212120f;
  width: 100%;
  border-radius: 5px;
  margin: 10px 0 20px;
  padding: 10px 0;
}

.form-control .form-select {
  font-size: 13px !important;
  height: 39px !important;
  color: #aaaaaa !important;
  font-weight: 400 !important;
}

.modal-password img {
  float: left;
  width: 10px;
  margin: 2px 8px 0px 13px;
}

.modal-password p {
  margin: 0px 0px;
  color: #ff7f03;
  font-size: 12px;
}

.modal-password button {
  border: none;
  background: transparent;
}

.modal-password i {
  position: relative;
  top: -15px;
  right: 25px;
  float: right;
  font-size: 10px;
  color: #ff7f03;
  transition: all 1s;
}

.modal-password:hover {
  background-color: #f8f8f8;
  text-decoration: underline;
  text-decoration-color: #ff7f03;
}

.modal-password:hover p {
  text-decoration: underline;
  cursor: pointer;
  text-decoration-color: #ff7f03;
}

.modal-password:hover i {
  right: 18px;
}

.modal-btn {
  width: 100%;
  background-color: #ff7f03;
  border: none;
  padding: 8px;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.4px;
  transition: all 100ms;
}

.quot_loc {
  border-radius: 3px;
  width: 35px;
  height: 35px;
  transition: all 100ms;
  border: 2px solid #fff;
  margin: -10px 27px 0 0;
  border-radius: 5px;
}

.quot_loc img {
  width: 12px;
  padding-bottom: 6px;
}

.select_loc_field {
  margin: 0 20px;
}

.select_loc_field .form-control {
  margin-top: -10px;
}

.quot_add_img {
  width: 32px;
  margin: -10px 0px 0 -10px;
}

.orange-triggers .clone_quot_img {
  width: 28px;
}

.po_btn {
  width: 100%;
  margin-top: 26px;
  background-color: #17C93B;
  border: none;
  padding: 8px;
  color: #fff;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.4px;
  transition: all 100ms;
}

.view_po_btn {
  background-color: #D9EAFB;
  border: none;
  padding: 11px 50px;
  color: #177DE2;
  border-radius: 3px;
  font-size: 12px;
  /* min-width: 70%; */
  font-weight: 400;
  letter-spacing: 0.4px;
  transition: all 100ms;
}

.add_po_btn {
  background-color: #17C93B29;
  border: none;
  margin-left: 11px;
  padding: 11px;
  /* min-width: 60%; */
  color: #17C93B;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  transition: all 100ms;
}

.add_po_btn img {
  width: 13px;
  margin-right: 5px;
}

.po_btn img {
  width: 13px;
  margin-right: 8px;
}

.modal-apply {
  margin-top: 20px;
  padding: 10px;
  word-wrap: normal;
}

.modal-btn img {
  width: 14px;
  margin: 0 5px 2px 0;
}

.customer-page {
  margin-top: 17px;
}

.modal-btn:hover {
  box-shadow: 0px 6px 6px #00000029;
}

.save-pages:hover {
  box-shadow: 0px 6px 6px #00000029;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.875rem;
  flex-direction: column;
  margin-top: 2px;
}

.modal-img {
  background-color: #f8f8f8;
  padding: 50px;
  border-radius: 50%;
}

.modal-img h4 {
  text-align: center;
}

.profile-imgs {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  margin: -10px;
}

.profile-img {
  width: 30px !important;
  height: 30px;
}

.profile input[type="file"] {
  display: block;
}

.choose-file {
  margin-top: 10px;
  padding: 9px 2rem 3px;
  border: 1px solid #ff7f03;
  display: table;
  color: #ff7f03;
  font-size: 13px;
  border-radius: 3px;
  cursor: pointer;
}

.choose-file:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 120px 0 rgba(235, 184, 90, 0.19);
}

/* Add Staff Modal Css End */

/* Login  Modal Popup Css Start */

.modal-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.1rem;
  padding: 40px 15px;
}


.modal-success img {
  width: 56px;
  height: 70px;
}

.modal-success h6 {
  font-weight: 450;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.4px;
}

/* Login  Modal Popup CssEnd */

/* Add Product Modal Css Start */

.modal-staff-product {
  margin: 5px 25px 5px 35px;
}

.modal-product {
  margin-top: 15px;
  font-size: 15px;
  font-weight: 450;
  letter-spacing: 0.4px;
}

.modal-product .form-control {
  margin-bottom: 25px;
}

.modal-product .form-control {
  color: black;
}

.modal-product .form-control:focus {
  border-bottom: none;
  border-color: #ff7f03 !important;
  background-color: #ffffff;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.product-btn {
  width: 100%;
  background-color: white;
  border: 1px solid #ff7f03;
  padding: 8px;
  color: #ff7f03;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 450;
  letter-spacing: 0.4px;
  transition: all 100ms;
  text-align: center;
  cursor: pointer;
}

.product-btn img {
  width: 15px;
  height: 15px;
  margin: -2px 3px 0 0;
}

.modal-unit {
  margin: 25px 30px 10px 30px;
}

.modal-unit .form-control {
  margin-bottom: 25px;
}

.modal-unit-popup {
  padding: 40px;
}

/* Add Product Modal Css End */

/* Edit Product Modal Css Start */

.head .form-control {
  position: relative;
  display: inline-block;
  right: 25px;
  height: 30px !important;
  font-size: 11px !important;
}

.head .form-control:focus {
  border: 1px solid #ff7f03;
  font-size: 13px;
}

.new-page {
  margin: 20px 0;
}

.modal-price {
  /* margin: 20px 0; */
  border: none;
  padding: 9px 20px;
  width: 100%;
  background: transparent;
  color: #ff7f03;
  background-color: #ff7f031a;
  text-align: left;
  font-size: 12px;
  font-weight: 450;
  border-radius: 3px;
  white-space: nowrap;
  letter-spacing: 0.4px;
}

.view img {
  visibility: hidden;
}

.modal-price i {
  float: right;
  position: relative;
  top: 3px;
  font-size: 12px;
  transition: all 1s linear;
}

.modal-price button {
  background-color: transparent;
  border: none;
  font-size: 13px;
  color: #ff7f03;
  margin-top: 6px;
}


.modal-price:hover {
  background-color: #ebe1ce;
  text-decoration: underline;
  text-decoration-color: #ff7f03;
}

.modal-price:hover button {
  text-decoration: underline;
  text-decoration-color: #ff7f03;
}

.modal-price:hover i {
  right: -10px;
}

.history {
  margin: 18px 40px;
}

/* Edit Product Modal Css End */

/* Reports  Page Css Start */
.staff-exe .quotation-part {
  position: relative;
  top: -40px;
  margin-top: 20px;
}

.staff-exe-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.staff-exe-head .popup-btn {
  float: right;
  position: absolute;
  top: 20px;
  padding: 3px 10px;
  right: 30px;
  font-size: 12px;
  color: #ff7f03;
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #ff7f03;
}

.popup-btn img {
  color: #ff7f03;
}

.staff-exe-head button:focus {
  background-color: #fff;
  color: #ff7f03;
  border: 1px solid #ff7f03;
}

.staff-exe-head h6 {
  margin-left: -100px !important;
}

/* Reports  Page Css End */

/* Filter Page Css Start */
.filter-page p {
  font-size: 13px;
  color: #000 !important;
  font-weight: 440 !important;
  font-family: "roboto";
  margin-bottom: -7px;
}

.role-id {
  margin-top: 10px;
}

.customer-check .form-check-label {
  font-size: 12.5px;
  font-weight: 400px !important;
  color: rgb(34, 34, 34);
}

.customer-check .stock_label {
  font-size: 13px;
  color: #AAAAAA;
  margin-top: 5px;
  margin-left: 4px;
  font-weight: 500 !important;
}

.customer-check .stock_labell {
  font-size: 13px;
  color: #AAAAAA;
  margin-top: 7px;
  margin-left: -5px;
  font-weight: 500 !important;
}

.filter-page {
  margin: 15px 10px 0px;
}

.customer-check .form-check-input {
  margin-top: 5px;
}

.filter-page .modal-price {
  margin-top: 20px;
  padding: 0.63rem 27px;
  text-align: center;
  cursor: pointer;
}

.table-class td {
  color: black;
}

.filter-page .modal-btn {
  font-size: 12px;
  padding: 0.63rem 27px;
}

.unit-page .table-class .table tbody tr td {
  font-size: 13px !important;
  color: #121212;
  font-weight: 450;
}

.nav-pills .nav-link .reports {
  position: relative;
  left: 110px !important;
  font-size: 10px;
}

/* Filter Page Css End */

/* Qutation Page Css Start */

.qutation-page {
  padding: 15px 0px 15px 3px;
  padding-bottom: 5px;
}

.quatation-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quatation-head h6 {
  margin: 10px;
  font-size: 14px;
  font-weight: 450;
  margin-left: -110px;
}

.qutation-page .table-class {
  padding: 15px 0;
}

.qutation-page .table-class tr:last-child {
  background-color: #f8f8f8;
}

.qutation-page .table-class tr:last-child td:first-child {
  text-align: right;
}

.qutation-page .table-class tr:last-child td:last-child {
  color: black;
  font-weight: 500;
}

.qutation-page .table-class tbody tr td {
  font-size: 13px !important;
  color: #121212;
  font-weight: 450;
}

.qutation-body-btn {
  width: 300px;
  background-color: #ff7f03;
  border: none;
  padding: 8px;
  color: #fff;
  border-radius: 3px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 450;
  letter-spacing: 0.4px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.loader {
  position: absolute;
  left: 40%;
  top: 50%;
}

.modal-unit {
  margin-top: 5px;
}

.modal-unit .modal-btn {
  margin-top: 10px;
  margin-bottom: 10px;
}

.gray-text {
  font-size: 12px;
  color: #aaaaaa;
}

.trash {
  margin-top: 10px;
  float: left;
  width: 50px;
  height: 50px;
  background-color: #ec282833;
  border-radius: 5px;
}

.location-btn {
  border: none;
  background-color: #17c93b;
  color: white;
  font-size: 11px;
  margin: 10px 20px;
  padding: 6px 10px;
  border-radius: 20px;
  cursor: pointer;
}

.location-btn img {
  color: white;
  margin: 0px 5px;
  width: 14px;
}

.view {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 10px;
}

.view .head-img {
  margin: 10px 10px;
  position: absolute;
  left: 10px;
  top: 10px;
}

.view .location-btn img {
  color: white;
}

.form {
  margin: 20px 20px -10px 30px;
}

.modal-unit .form-control {
  margin-top: 25px;
}

.modal-unit .unit {
  margin: 10px 0 10px;
}

.template {
  padding: 20px;
  height: 75vh;
}

.template-card {
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.template-card img {
  height: 200px;
  clip: auto;
  border-radius: inherit;
}

.template-card p {
  margin: 10px;
  font-size: 13px;
  font-weight: 500;
}

.select-template {
  margin: 0px 10px 14px;
  border: none;
  background-color: #ff7f03;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 6px 10px;
  width: 90%;
  white-space: nowrap;
}

.select-templatess {
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 0 2px #bc5c00;
}

.convert_boq {
  background-color: #C84163;
  color: #FFFFFF;
  margin-top: 14px;
}

.select-templatess:active {
  background-color: #bc5c00;
  box-shadow: 0 2px #bc5c00;
  transform: translateY(2px);
}

.select-templatess {
  margin: 10px 10px 14px;
  border: none;
  background-color: #038432;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  padding: 6px 10px;
  width: 90%;
  white-space: nowrap;
  box-shadow: 0 2px #114624;
  margin-top: 15px;
}


.select-templatess:active {
  background-color: #114624;
  box-shadow: 0 2px #114624;
  transform: translateY(2px);
}

.select-template {
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 0 2px #bc5c00;
}

.select-template:active {
  background-color: #bc5c00;
  box-shadow: 0 2px #bc5c00;
  transform: translateY(2px);
}

.template-card img:hover {
  cursor: pointer;
}

.preview-img {
  width: 100%;
  margin: 20px;
}

.btn {
  padding: 12px;
}

.nr {
  margin-right: 58px !important;
  margin-top: 2px;
}

/* Qutation Page Css End */

/* Customer Page Css Start */

.customer {
  width: 100%;
  margin: 0px 50px 20px 60px;
}

.customers {
  width: 100%;
  margin: 20px 50px 20px 50px;
}

.initial {
  margin: 25px 0px 0px;
}

.customer-head {
  margin: 20px 0px -5px 50px;
  font-size: 15px;
  font-weight: 450;
  letter-spacing: 0.4px;
}

.customer-btn {
  margin: 0px 0px 5px;
  width: 100%;
  background-color: #ff7f03;
  border: none;
  padding: 8px;
  color: #fff;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.4px;
  transition: all 100ms;
}

.customer-btn:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 120px 0 rgba(235, 184, 90, 0.19);
}

/* Customer Page Css End */

.header-head {
  margin-bottom: 20px;
}

.header-head h4 {
  font-size: 15px;
  font-weight: 450;
  letter-spacing: 0.4px;
}

.header-head p {
  margin: 10px 0px 10px;
  margin-left: 0px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.header-logo {
  width: 20rem;
  background-color: #2c2424;
  height: 80px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.header-logo img {
  width: 80%;
}

.form-check-label {
  margin: 4px 0 0;
  font-size: 11px;
  color: #aaaaaa;
  font-weight: 500;
  white-space: nowrap;
}

.customer-span {
  margin: 8px 0px;
  font-size: 14px;
  color: #aaaaaa;
  font-weight: 500;
  white-space: nowrap;
}

.custom-btn {
  position: absolute;
  top: 0px;
  width: 36%;
  right: 2%;
  text-align: center;
}

.boq_btn {
  position: absolute;
  top: 23%;
  width: 18%;
  right: 13.5%;
  text-align: center;
  cursor: pointer;
}


.customer-sub {
  /* margin: 15px 20px 15px 16px; */
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancel-btn i {
  position: relative;
  left: 30px !important;
  top: 113px;
}

.cancel-btn {
  width: 100%;
  margin-right: 30%;
  background-color: #0000001a;
  border: none;
  padding: 8px;
  color: #121212;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 450;
  letter-spacing: 0.4px;
  transition: all 100ms;
}

.close-btn {
  background-color: #0000001a;
  border: none;
  padding: 7px 30px;
  margin-right: 10px;
  color: #121212;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 450;
  letter-spacing: 0.4px;
  transition: all 100ms;
}

.cancel-btn:hover {
  background-color: #aaaaaa7f;
}

.new-btn {
  margin-top: 90px;
}

.qutation-col {
  /* margin: -20px 20px 12px 0; */
  height: 5.8rem;
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 5px;
}

.col {
  margin: 15px 0px -18px;
}

.qutation-col p {
  padding-top: 15px;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 450;
}

.col p {
  margin: 10px 0px 0px -10px;
  font-size: 14px;
  font-weight: 450;
}

.qutation_check {
  margin: 15px 15px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.qutation {
  margin-left: -10px;
}

.qutation-btn {
  width: 40%;
  margin-right: 50%;
}

.qutation .fa-arrow-left {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.fa-arrow-left {
  cursor: pointer !important;
  background-color: transparent !important;
}

.qutation-btn img {
  color: black;
  margin-right: 11px;
  width: 11px;
}

.two-btn {
  margin-top: 42px;
}

.delete {
  position: absolute;
  top: 55px;
  right: 30px;
  width: 34px;
  height: 38px;
  border-radius: 4px;
  background-color: #ec282833;
}

.delete i {
  margin: 2px 6px;
  margin-top: 10px;
  font-size: 6px;
  color: #ec2828;
}

.pos-rel {
  position: relative;
}

.pos-rel>.delete {
  top: 0px !important;
  right: 25px !important;
  cursor: pointer;
}

.loc>.delete {
  top: 5px !important;
  right: 22px !important;
  cursor: pointer;
}

.group {
  margin-top: 10px;
}

.foo {
  width: 30px;
  position: absolute;
  top: 10px;
  right: 39px;
  height: 30px;
}

.foos {
  width: 33px;
  position: absolute;
  top: 25px;
  right: 27px;
  height: 36px;
}

.footer_add {
  width: 36px;
  position: absolute;
  top: 28px;
  height: 36px;
}

.foo img {
  margin: 0px;
  width: 12px;
  margin-top: -10px;
}

.foot {
  position: absolute;
  width: 35px;
  height: 35px;
  right: -35px;
  top: 40%;
}

.foot i {
  font-size: 12px;
}

.next {
  margin-right: 45%;
}

.actions {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: -12px;
  float: right;
}

.Edit-view {
  border: none;
  background-color: transparent;
  font-size: 12px;
  color: #17C93B;
  font-weight: 450;
  cursor: pointer;
  text-decoration: underline;
}

.Edit-edit {
  border: none;
  background-color: transparent;
  font-size: 12px;
  color: #177de2;
  font-weight: 450;
  cursor: pointer;
  text-decoration: underline;
}

.Edit-delete {
  border: none;
  background-color: transparent;
  font-size: 12px;
  color: #ec2828;
  font-weight: 450;
  cursor: pointer;
  text-decoration: underline;
}

.empty-data {
  position: absolute;
  left: 45%;
  top: 50%;
  font-size: 17px;
  white-space: nowrap;
}

.empty_data {
  position: relative;
  margin: 50px 0;
  left: 150%;
  font-size: 17px;
  text-align: center;
}

.empty-datas {
  position: absolute;
  left: 40%;
  top: 55%;
  font-size: 17px;
  white-space: nowrap;
}

.empty-datass {
  position: absolute;
  left: 30%;
  top: 55%;
  font-size: 17px;
  white-space: nowrap;
}

.modal-row-form {
  margin: 20px 30px;
}

.hidden {
  display: none;
}

.location {
  width: 70%;
  margin: 20px 20px;
}

.add {
  margin-top: 30px;
}

.del {
  position: absolute;
  top: 35px;
  right: -80px;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  background-color: #ec282833;
  cursor: pointer;
}

.del i {
  margin: 8px 6px;
  font-size: 6px;
  color: #ec2828;
}

.prepare {
  /* margin: 0 10px 0px 140px; */
  height: 2rem;
  border: 1px solid #AAAAAA80;
  border-radius: 3px;
  word-wrap: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  white-space: nowrap;
}

.pre {
  height: 2rem;
  border: 1px solid #AAAAAA80;
  border-radius: 3px;
  word-wrap: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  white-space: nowrap;

}

.pre>p {
  margin: 6px;
  font-size: 10px;
  color: #AAAAAA;
  word-wrap: none;
}

.prepare>p {
  margin: 6px;
  font-size: 10px;
  color: #AAAAAA;
  word-wrap: none;
}

.pop {
  width: 50%;
  margin-right: 20px;
}

.pop>img {
  margin-right: 7px;
  margin-top: -2px;
}

.error {
  position: absolute;
  bottom: 40px;
}

.pro {
  margin-top: -5px;
}

.back_img {
  width: 30px;
  margin-top: -5px;
  cursor: pointer;
}

.close_img {
  width: 30px;
  margin-top: 17px;
  margin-left: 90px;
  cursor: pointer;
}

.reset {
  word-wrap: none;
}

.quot {
  /* margin-left: 20px;
  margin-right: -20px; */
  position: absolute;
  left: -5px;
  width: 40%;
  word-wrap: none;
}

.plus-rel {
  position: absolute;
  right: 70px;
}

.clone {
  background-color: #000;
  margin-top: 15px;
  box-shadow: 0 2px rgb(48, 47, 47);
}

.deliver {
  background-color: #0ABCFF;
  margin-top: 15px;
  min-width: 110px;
  box-shadow: 0 2px rgb(48, 47, 47);
}

.verify_po {
  background-color: #2BD998;
  margin-top: 15px;
  min-width: 110px;
  box-shadow: 0 2px rgb(48, 47, 47);
}

.verify_po img {
  width: 12px;
  margin-right: 5px;
}

.deliver img {
  width: 12px;
  color: #fff;
  margin-right: 5px;
}

.verify_po:active,
.deliver:active {
  background-color: rgb(48, 47, 47);
  box-shadow: 0 2px rgb(48, 47, 47);
  transform: translateY(2px);
}

.sent {
  background-color: #B01A40;
  margin-top: 15px;
  box-shadow: 0 2px rgb(48, 47, 47);
}

.send_mail {
  background-color: #E15098;
  margin-top: 15px;
  box-shadow: 0 2px rgb(48, 47, 47);
}

.send_mail:active {
  background-color: rgb(48, 47, 47);
  box-shadow: 0 2px rgb(48, 47, 47);
  transform: translateY(2px);
}

.sent:active {
  background-color: rgb(48, 47, 47);
  box-shadow: 0 2px rgb(48, 47, 47);
  transform: translateY(2px);
}

.clone:active {
  background-color: rgb(48, 47, 47);
  box-shadow: 0 2px rgb(48, 47, 47);
  transform: translateY(2px);
}

.modal_view {
  width: 80%;
}

.modal_head {
  word-wrap: none;
  width: 2rem;
  text-overflow: ellipsis;
  overflow: visible;
}

.mod_head {
  white-space: nowrap;
  width: 100%;
}

.filter-background .staff {
  position: absolute;
  right: 13.6%;
  top: 3.1%;
  padding: 6px 25px;
  font-weight: 500;
  font-size: 12px;
}

.global_staff {
  position: absolute;
  top: 3%;
  right: 22%;
}

.generate {
  padding: 7px;
  background-color: #BB4AF2;
  margin-top: 15px;
  box-shadow: 0 2px #7a2da1;
}

.generate:active {
  background-color: #7a2da1;
  box-shadow: 0 2px #7a2da1;
  transform: translateY(2px);
}


.delete_quot {
  margin-top: 15px;
  background-color: #EC2828;
  box-shadow: 0 2px #7d2020;
}



.transfer {
  margin-top: 15px;
  background-color: #B7D80C;
  box-shadow: 0 2px #879c1f;
}

.transfer img {
  width: 13px;
  margin: 0 5px;
}

.deliver_boq {
  margin-top: 15px;
  background-color: #0ABCFF;
  box-shadow: 0 2px #1e7c9e;
}

.deliver_boq img {
  width: 13px;
  margin: 0 5px;
}


.delete_quot:active {
  background-color: #7d2020;
  box-shadow: 0 2px #7d2020;
  transform: translateY(2px);
}


.download {
  background-color: #177DE2;
  margin-top: 15px;
  box-shadow: 0 2px #1a4875;
}

.download:active {
  background-color: #1a4875;
  box-shadow: 0 2px #1a4875;
  transform: translateY(2px);
}

.print-view {
  margin-top: 15px;
  background-color: #c917a8;
  box-shadow: 0 2px #7d146a;
}

.print-view:active {
  background-color: #7d146a;
  box-shadow: 0 2px #7d146a;
  transform: translateY(2px);
}

.print-view i {
  color: #fff !important;
  font-size: 13px !important;
  padding-right: 5px;
  margin-top: 3px;
}

.generate img {
  width: 13px;
  margin: 0 5px 2px;
}

.download img,
.delete_quot img,
.clone img,
.edit_quot img {
  width: 12px;
  margin: 0 5px 2px;
}

.cancel img {
  width: 14px;
}

.modal-success div .clone_popup {
  border-radius: 50px;
}

.modal-success div span {
  color: #AAAAAA;
}

.modal-success div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.clone_popup {
  width: 30px;
  background-color: #000;
  border-radius: 70%;
}

.pointer {
  position: absolute;
  right: 15%;
  top: 53%;
  right: 23%;
}

.confirm_password {
  position: absolute;
  right: 15%;
  top: 55%;
  right: 10%;
}

.cur_password {
  position: absolute;
  right: 15%;
  top: 11%;
  right: 10%;
}

.new_password {
  position: absolute;
  right: 15%;
  top: 33%;
  right: 10%;
}

.new_pass {
  position: absolute;
  right: 15%;
  top: 15%;
  right: 10%;
}

.confirm_pass {
  position: absolute;
  right: 15%;
  top: 42%;
  right: 10%;
}

.log-eyes {
  font-size: 12px;
  color: #cfcece;
}

.log-eye {
  font-size: 12px;
  color: #cfcece;
}

.group_product_btn {
  /* width: 100%; */
  border: none;
  padding: 8px 28px 8px 28px;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  transition: all 100ms;
  border-radius: 50px;
  background-color: #17C93B;
  white-space: nowrap;

}

.group_new_btn {
  border: none;
  padding: 5px 20px 5px 20px;
  color: #000;
  border-radius: 3px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4px;
  transition: all 100ms;
  border-radius: 50px;
  background-color: #fff;
  white-space: nowrap;
  margin-bottom: 5px;
}

.group_product_btn {
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 0 3px #165f24;
}

.group_product_btn:active {
  background-color: #165f24;
  box-shadow: 0 3px #165f24;
  transform: translateY(2px);
}

.group_product_btn img {
  width: 15px;
  margin: 0 7px 3px;
}



.group_product_btns {
  /* width: 100%; */
  border: none;
  padding: 8px;
  padding-right: 12px;
  white-space: nowrap;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  transition: all 100ms;
  border-radius: 50px;
  background-color: #177DE2;

}

.group_product_btns {
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 0 3px #1b4978;
}

.group_product_btns:active {
  background-color: #1b4978;
  box-shadow: 0 3px #1b4978;
  transform: translateY(2px);
}

.group_product_btns img {
  width: 15px;
  margin: 0 7px 3px;
}

.product_total {
  position: absolute;
  margin: 20px;
  width: 95%;
  bottom: 20px;
}

.total_footer {
  position: absolute;
  width: 90%;
  bottom: 15%;
}

.total_cost {
  position: absolute;
  right: 30px;
  bottom: 5px;
}

.edit_trash {
  /* position: absolute;
  top: 3px;
  right: 20px; */
  /* width: 34px; */
  height: 34px;
  padding: 5px 11px;
  border-radius: 4px;
  background-color: #ec282833;
  cursor: pointer;
  /* margin-right: 15px;
  margin-left: 15px; */
}

.edit_trash img {
  width: 13px;
}

.edit_trashs {
  height: 34px;
  padding: 5px 11px;
  border-radius: 4px;
  background-color: #ec282833;
  cursor: pointer;
  /* margin-right: 15px;
  margin-left: 15px; */
}

.edit_trashs img {
  width: 20px !important;
}

.del_po {
  position: relative;
  top: 0px;
  /* left: 5px; */
  width: 34px;
  padding: 5px 10px;
  height: 34px;
  border-radius: 4px;
  background-color: #ec282833;
  cursor: pointer;
}

.del_po img {
  width: 13px;
}


.del_trash {
  position: absolute;
  top: 3px;
  right: 25px;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  background-color: #ec282833;
  cursor: pointer;
}

.foo_trash {
  top: 26px;
}

.footer_delete {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  background-color: #ec282833;
  cursor: pointer;
}

.del_po i {
  margin: 2px 6px;
  margin-top: 10px !important;
  font-size: 6px;
  color: #ec2828;
}

.del_trash i {
  margin: 2px 6px;
  margin-top: 10px !important;
  font-size: 6px;
  color: #ec2828;
}


.new-one {
  background-color: #F8F8F8;
  padding-top: 11px;
  padding-bottom: 1px;
  padding-right: 20px;
  position: relative;
  margin: 30px;
  bottom: 0px;
  width: 95%;
}

.new-oneee {
  background-color: #F8F8F8;
  margin: 30px 10px;
  padding-top: 11px;
  padding-bottom: 1px;
  padding-right: 10px;
  position: absolute;
  bottom: 120px;
  width: 95%;
}

.new_footer {
  background-color: #F8F8F8;
  margin: 30px 10px;
  padding-top: 11px;
  padding-bottom: 1px;
  padding-right: 10px;
  position: absolute;
  bottom: 120px;
  width: 93.5%;
}

.new-ones {
  background-color: #F8F8F8;
  margin-left: 10px;
  padding-top: 11px;
  padding-bottom: 1px;
  padding-right: 20px;
  position: absolute;
  bottom: 60px;
  width: 95%;
}

.new-one p,
.new-oneee p {
  font-weight: bold;
  text-align: right;
}

.new-ones p {
  font-weight: bold;
  text-align: right;
}

/* 
if (sortField === "") {
  this.props.dispatch(getQutationList(pageNo, searchKey, {}, {}));
} else {
  this.props.dispatch(getQutationList(pageNo, searchKey, sort, {}));
} */

.three-btn {
  /* width: 100%; */
  margin-top: 20px;
  background-color: #ff7f03;
  border: none;
  padding: 6px 18px;
  color: #fff;
  border-radius: 43px;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.4px;
  transition: all 100ms;
  margin-left: 43.5%;
}

.three-btn {
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 0 3px #bd6a18;
}

.three-btn:active {
  background-color: #bd6a18;
  box-shadow: 0 3px #bd6a18;
  transform: translateY(2px);
}

.tax {
  color: #aaaaaa;
  font-size: 10px;
  font-weight: 100;
  white-space: nowrap;
}


/* .preview-img > div{
  padding: 40px;
}
.template-two img,
.template-three img{
    width: 24px!important;
} */


.deletes-s {
  position: absolute;
  top: 5px;
  right: 20px;
  cursor: pointer;
}

.deletes {
  position: absolute;
  top: 5px;
  right: 0px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #ec282833;
}

.deletes i {
  margin: 2px 5px;
  margin-top: 10px;
  font-size: 6px;
  color: #ec2828;
}

.fa-save {
  font-size: 15px !important;
  color: #fff;
}

.save-fa img {
  border-radius: 3px;
  width: 30px;
  cursor: pointer;
  margin-top: 5px !important;
  margin-left: -10px !important;
  padding: 7px;
  background-color: #ff7f03;
}

.empty-data-a {
  position: relative;
  left: 550px;
  padding-top: 50px;
  font-size: 17px;
  white-space: nowrap;
}

.product-edit img {
  margin-bottom: 20px !important;
}

.error_popup {
  margin: 20px;
  width: 90%;
}

.modal-success h5 {
  text-align: center;
}


.add-button-one {
  border: none;
  width: 100%;
  padding: 10px 18px 10px 18px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  transition: all 100ms;
  border-radius: 3px;
  background-color: #17C93B;
  white-space: nowrap;
}

.add-button-one img {
  width: 15px;
  margin-right: 5px;
}

.add-button-two {
  width: 100%;
  border: none;
  padding: 5px 14px 5px 14px;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  transition: all 100ms;
  background-color: #070b5a;
  white-space: nowrap;
}

.add-button-two img {
  width: 15px;
  margin-right: 5px;
}